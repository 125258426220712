<template>
  <a
    :href="enable ? link : null"
    target="_blank"
    :style="{ cursor: !enable ? 'not-allowed' : null }"
    style="position: relative;"
  >
    <img
      :style="{ width: size, height: size, opacity: enable ? 1 :  0.4 }"
      style="filter: drop-shadow(0px 0px 7px #555);"
      src="images/whatsapp-icon.png"
    />
    <q-tooltip anchor="top middle" self="bottom right" content-class="bg-white q-pa-md shadow-3">
      <div
        style="
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #474554;
          max-width: 285px;
        "
        class="q-mb-sm"
      >
        {{
          enable ? '¿Necesitas ayuda?' : 'Estamos fuera de horario'
        }}
      </div>
      <div
        style="
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #767481;
          max-width: 285px;
        "
      >
        {{
          enable ? 
            'Inicia una conversación a través de Whatsapp con nuestro equipo para obtener ayuda.'
            : 'Nuestro equipo de soporte puede ayudarte con gusto de Lunes a Viernes en un horario de 08:00am a 05:00pm.'
        }}
      </div>
    </q-tooltip>
  </a>
</template>

<script>

export default {
  name: 'whatsapp-button',
  props: {
    phoneNumber:{
      type: String,
      required:false, 
      default: '' + process.env.WHATSAPPNUMBER 
    },
    size: {
      type: String,
      default: '55px'
    }
  },
  computed: {
    link () {
      return 'https://wa.me/' + this.phoneNumber
    }
  },
  data () {
    return { enable: false }
  },
  methods: {
    setEnable () {
      const validHorario = () => {
        const date = new Date()

        if (![1,2,3,4,5].includes(date.getDay())) return false

        const start = 8 * 60
        const end =  17 * 60
        const now = date.getHours() * 60 + date.getMinutes()

        return (start <= now && now <= end)
      }

      this.enable = validHorario()
    }
  },
  mounted () {
    this.setEnable()
    this.intervalId = setInterval(this.setEnable, 5000)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  }
}
</script>
