
const logOut_Mixin = {
  created: function () {
    this.$cannaDebug('log Out -- Mixin created')
  },
  data () {
    return {
      _logoutMixinVer: '1.0.1'
    }
  },
  computed: {
    _alertState () {
      return this.$store.state.myapp.appSessionAlert
    }
  },
  methods: {
    ex_handlerErrorFormNoSessionRequest: function (response, error, formInstance, formName, TranslateFields, frmErrorMainVarString, useNotifyAlert, notifyAlertStyle ){
      this.$cannaDebug('-- Error request -- ex_handlerErrorFormNoSessionRequest')
      this.$cannaDebug('-- Error request -- plain error --ex_handlerErrorFormNoSessionRequest')
      var useNotify = (useNotifyAlert===undefined) ?  true : useNotifyAlert
      var notifyStyle = (notifyAlertStyle===undefined || notifyAlertStyle===undefined || notifyAlertStyle==='')? 'negative' : notifyAlertStyle
      var myDatax = (response.data!==undefined) ? response.data.data : null // response.data.data
      var myMsg = ''
      try {
        if (response !== undefined  && response.data !== undefined) {
          if (response.data.data) {
            if (Array.isArray(response.data.data)) {
              if(response.data.data.length<=1) { 
                myMsg = '' + response.data.data[0].message
                if (response.data.data[0].hasOwnProperty('message') && response.data.data[0].hasOwnProperty('field')) {
                  var myObject = {}
                  var indexFX = TranslateFields.findIndex(item => item.fieldNameAPi === response.data.data[0].field )
                  var fieldNombreUse = ''
                  if (indexFX>=0){ 
                    fieldNombreUse = TranslateFields[indexFX].fieldNameForm
                  } else { 
                    fieldNombreUse = response.data.data[0].field
                  }
                  myObject[fieldNombreUse] = response.data.data[0].message
                  formInstance.setErrors(myObject);
                  if (frmErrorMainVarString!=undefined) {
                    this.frmMainErrors[frmErrorMainVarString] = response.data.data[0].message
                  }
                } else {
                  if (response.data.data[0].hasOwnProperty('message')) {
                    myMsg = '' + response.data.data[0].message // + ' - ' + response.data.data.status
                  } else { 
                    myMsg = 'Error x000 - inesperado del servidor (api), consulte a soporte'
                  }
                }
                //----------
              } else { // Esta opcion deberia cuando hay errores en varios campos 
                myMsg = ''
                response.data.data.forEach( itemError => {
                  if (itemError.hasOwnProperty('message') && itemError.hasOwnProperty('field')) {
                    var myObject = {}
                    var indexFX = TranslateFields.findIndex(itemx => itemx.fieldNameAPi === itemError.field )
                    var fieldNombreUse = (indexFX>=0) ?  TranslateFields[indexFX].fieldNameForm :  itemError.field
                    myObject[fieldNombreUse] = itemError.message
                    formInstance.setErrors(myObject);
                    myMsg += (myMsg!=='') ? '<br>': ''
                    myMsg += itemError.message
                  } else { 
                    if (itemError.hasOwnProperty('message')) {
                      myMsg += (myMsg!=='') ? '<br>': ''
                      myMsg += itemError.message
                    }
                  }
                })
              }
            } else {
              if (typeof response.data.data === 'object' && response.data.data.constructor === Object ) {
                if (response.data.data.hasOwnProperty('message')) {
                  myMsg = '' + response.data.data.message
                } else { 
                  myMsg = 'Error x000 inesperado del servidor (api), consulte a soporte'
                }
              } else {
                myMsg = 'Error x001 inesperado del servidor (api), consulte a soporte'
              }
            }
          } else {
            myMsg = 'Error x002 inesperado del servidor (api), consulte a soporte'
          }
        } else {
          myMsg = 'Error x003 no hay respuesta por parte del servidor, consulte a soporte'
        }
      } catch (errx) {
        myMsg = 'Error  x009 no hay respuesta por parte del servidor, consulte a soporte'
      }
      if (useNotify === true ){
        if (notifyStyle!=='negative' && notifyStyle!=='warning'){
          notifyStyle = 'negative'
        }
        this.$q.notify({
          type:  notifyStyle, //'negative',
          position: 'top',
          message: '' + myMsg
        })

      } else {
        if (frmErrorMainVarString!=undefined) {
          this.frmMainErrors[frmErrorMainVarString] = myMsg
        }
      }
    },
    ex_handlerErrorFormRequest: function (response, error, formInstance, formName, TranslateFields, frmErrorMainVarString ){
      this.$cannaDebug('-- Error request -- _handleErrorRequest')
      this.$cannaDebug('-- Error request -- plain error --_handleErrorRequest')
      if (response.type === 'errorSession') {
        this.$store.commit('myapp/appLogout', { exit: true })
      } else {
        var useNotify = true
        var myDatax =  (response.data!==undefined) ? response.data.data : null // response.data.data
        var myMsg = ''
        try {
          if (response !== undefined && response.data !== undefined) {
            if (response.data.data) {
              if (Array.isArray(response.data.data)) {
                if(response.data.data.length<=1) { 

                  var itemObj = Array.isArray( response.data.data[0]) ?  response.data.data[0][0] : response.data.data[0]
                  
                  myMsg = '' + itemObj.message
                  if (itemObj.hasOwnProperty('message') && itemObj.hasOwnProperty('field')) {
                    useNotify = false
                    var myObject = {}
                    var indexFX = TranslateFields.findIndex(item => item.fieldNameAPi === itemObj.field )
                    var fieldNombreUse = ''
                    if (indexFX>=0){ 
                      fieldNombreUse = TranslateFields[indexFX].fieldNameForm
                    } else { 
                      fieldNombreUse = itemObj.field
                    }
                    myObject[fieldNombreUse] = itemObj.message
                    formInstance.setErrors(myObject);
                    if (frmErrorMainVarString!=undefined) {
                      this.frmMainErrors[frmErrorMainVarString] = itemObj.message
                    }
                  } else {
                    if (itemObj.hasOwnProperty('message')) {
                      myMsg = '' + itemObj.message // + ' - ' + response.data.data.status
                    } else { 
                      myMsg = 'Error x000 - inesperado del servidor (api), consulte a soporte'
                    }
                  }

                  // myMsg = '' + response.data.data[0].message
                  // if (response.data.data[0].hasOwnProperty('message') && response.data.data[0].hasOwnProperty('field')) {
                  //   useNotify = false
                  //   var myObject = {}
                  //   var indexFX = TranslateFields.findIndex(item => item.fieldNameAPi === response.data.data[0].field )
                  //   var fieldNombreUse = ''
                  //   if (indexFX>=0){ 
                  //     fieldNombreUse = TranslateFields[indexFX].fieldNameForm
                  //   } else { 
                  //     fieldNombreUse = response.data.data[0].field
                  //   }
                  //   myObject[fieldNombreUse] = response.data.data[0].message
                  //   formInstance.setErrors(myObject);
                  //   if (frmErrorMainVarString!=undefined) {
                  //     this.frmMainErrors[frmErrorMainVarString] = response.data.data[0].message
                  //   }
                  // } else {
                  //   if (response.data.data[0].hasOwnProperty('message')) {
                  //     myMsg = '' + response.data.data[0].message // + ' - ' + response.data.data.status
                  //   } else { 
                  //     myMsg = 'Error x000 - inesperado del servidor (api), consulte a soporte'
                  //   }
                  // }
                  
                  //----------
                } else { // Esta opcion deberia cuando hay errores en varios campos 
                  myMsg = ''
                  response.data.data.forEach( itemError => {

                    var itemObj = Array.isArray(itemError) ? itemError[0] : itemError
                    if (itemObj.hasOwnProperty('message') && itemObj.hasOwnProperty('field')) {
                      if (useNotify!==false){ 
                        useNotify = false
                      }
                      var myObject = {}
                      var indexFX = TranslateFields.findIndex(itemx => itemx.fieldNameAPi === itemObj.field )
                      var fieldNombreUse = (indexFX>=0) ?  TranslateFields[indexFX].fieldNameForm :  itemObj.field
                      myObject[fieldNombreUse] = itemObj.message
                      formInstance.setErrors(myObject);
                      myMsg += (myMsg!=='') ? '<br>': ''
                      myMsg += itemObj.message
                    } else { 
                      if (itemObj.hasOwnProperty('message')) {
                        myMsg += (myMsg!=='') ? '<br>': ''
                        myMsg += itemObj.message
                      }
                    }
                  })
                  if (myMsg!==''){ 
                    if (frmErrorMainVarString!=undefined) {
                      this.frmMainErrors[frmErrorMainVarString] = myMsg
                    }
                  }
                  //----------
                }
              } else {
                if (typeof response.data.data === 'object' && response.data.data.constructor === Object ) {
                  if (response.data.data.hasOwnProperty('message')) {
                    myMsg = '' + response.data.data.message + ''
                  } else { 
                    useNotify = true
                    myMsg = 'Error x000 inesperado del servidor (api), consulte a soporte'
                  }
                } else {
                  useNotify = true
                  myMsg = 'Error x001 inesperado del servidor (api), consulte a soporte'
                }
              }
            } else {
              useNotify = true
              myMsg = 'Error x002 inesperado del servidor (api), consulte a soporte'
            }
          } else {
            useNotify = true
            myMsg = 'Error x003 no hay respuesta por parte del servidor, consulte a soporte'
          }
        } catch (errx) {
          useNotify = true
          myMsg = 'Error  x009 no hay respuesta por parte del servidor, consulte a soporte'
        }
        if (useNotify === true ){

          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: '' + myMsg
          })
        }
      }
    },
    ex_handleErrorRequest: function (response, error) {
      this.$cannaDebug('-- Error request -- _handleErrorRequest')
      if (response.type === 'errorSession') {
        this.$store.commit('myapp/appLogout', { exit: true })
      } else {
        this.$cannaDebug('-- Error request -- plain error --_handleErrorRequest')
        var myMsg = ''
        try {
          if (response !== undefined && response.data !== undefined) {
            if (response.data.data) {
              if (Array.isArray(response.data.data)) {
                var myError = response.data.data[0]
                myMsg = '' + myError.message
              } else {
                if (response.data.data.hasOwnProperty('message')) {
                  myMsg = '' + response.data.data.message // + ' - ' + response.data.data.status
                } else { 
                  myMsg = 'Error x000 - inesperado del servidor (api), consulte a soporte'
                }
              }
            } else {
              myMsg = 'Error x001 inesperado del servidor (api), consulte a soporte'
            }
          } else {
            myMsg = 'Error x003 no hay respuesta por parte del servidor, consulte a soporte'
          }
        } catch (errx) {
          myMsg = 'Error  x009 no hay respuesta por parte del servidor, consulte a soporte'
        }

        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: '' + myMsg
        })

      }
    },
    ex_handleErrorRequestSessionOnly: function (response) {
      this.$cannaDebug('-- Error request -- _handleErrorRequest')
      if (response.type === 'errorSession') {
        this.$store.commit('myapp/appLogout', { exit: true })
      }
    }
  }
}

export default logOut_Mixin
