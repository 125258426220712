import { render, staticRenderFns } from "./document-view.vue?vue&type=template&id=45e93407&"
import script from "./document-view.vue?vue&type=script&lang=js&"
export * from "./document-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QCircularProgress});
