<template>
  <!-- Este componente se encarga úncamente de mostrar un documento
    a partir de un parámetro url de nuestra api-->
  <div
    v-if="src && !['doc', 'docx'].includes(extension) && !!extension"
    style="position: relative;min-height: 60px;"
  >
    <template v-if="link">
      <!-- sección solo para mostrar archivos pdf -->
      <template v-if="extension === 'pdf'">
        <pdf-visor
          :key="link"
          :src="link"
          :file-name="fileName"
          :no-print-button="$q.screen.lt.sm"
          style="height: 500px;width: 100%;"
        />
      </template>
      <!-- sección para mostrar todo lo que no sea pdf como imagen -->
      <template v-else>
        <img :src="link" style="max-width: 100%;max-height: 100%;" />
      </template>
    </template>
    <!-- Animación mientras carga el documento -->
    <q-inner-loading :showing="loading">
      <q-circular-progress
        indeterminate
        size="50px"
        color="app_mainBtn"
        class="q-ma-md"
      />
    </q-inner-loading>
  </div>
</template>

<script>
import pdfVisor from 'components/pdf-visor'

export default {
  name: 'document-view',
  // recibe estilos para asignar al visor de pdf
  props: ['src', 'pdfStyle', 'fileName'],
  components: { pdfVisor },
  data () {
    return {
      link: '',
      loading: false,
      progress: 0
    }
  },
  watch: {
    src () {
      // actualiza el archivo del componente cada vez que cambie la url recibida
      this.getFile()
    }
  },
  computed: {
    extension () {
      // obtiene la extensión del archivo para saber cómo mostrarlo
      return (this.src || '').split('.').pop() || ''
    }
  },
  methods: {
    getFile () {
      // petición del archivo del servidor,
      // únicamente cuando existe el parámetro src
      if (!this.src) return

      this.loading = true

      this.$cannaApiService.get(this.src, { responseType: 'arraybuffer' })
        .then(({ data }) => {

          const types = {
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            pdf: 'application/pdf'
          }

          const type = types[this.extension] || 'image/' + this.extension

          const file = new Blob([data], { type })

          this.link = URL.createObjectURL(file)

          // envía la url para que esté disponible para el componente padre
          this.$emit('ready', this.link)
        })
        // si la petición falla simplemente no actualiza la información del archivo
        .catch(e => null)
        // siempre quita el loading al finalizar petición, ya sea exitosa o no
        .finally(() => (this.loading = false))
    }
  },
  mounted () {
    // obtiene el archivo cuando monta el componente
    const previewAble = !!this.extension && ['pdf'].includes(this.extension)
    this.$emit('preview-able', previewAble)
    this.getFile()
  }
}
</script>
