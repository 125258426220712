<template>
  <!-- svg icons for the app -->
  <q-icon 
    :size="size" 
    :class="myClasses"
    @mouseover="onMouseOver"
    @mouseleave="onMouseOut"
    @click.stop="onClick"
  >

    <template v-if="(iconType === 'list')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base_light" d="M11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8H19C19.5523 8 20 7.55228 20 7C20 6.44772 19.5523 6 19 6H11ZM10 12C10 11.4477 10.4477 11 11 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H11C10.4477 13 10 12.5523 10 12ZM10 17C10 16.4477 10.4477 16 11 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H11C10.4477 18 10 17.5523 10 17Z" />
        <path class="icon_base" d="M5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H7C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6H5ZM4 12C4 11.4477 4.44772 11 5 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H5C4.44772 13 4 12.5523 4 12ZM4 17C4 16.4477 4.44772 16 5 16H7C7.55228 16 8 16.4477 8 17C8 17.5523 7.55228 18 7 18H5C4.44772 18 4 17.5523 4 17Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'grid')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="icon_base" d="M14 6C13.4477 6 13 6.44772 13 7V10C13 10.5523 13.4477 11 14 11H18C18.5523 11 19 10.5523 19 10V7C19 6.44772 18.5523 6 18 6H14ZM6 13C5.44772 13 5 13.4477 5 14V17C5 17.5523 5.44772 18 6 18H10C10.5523 18 11 17.5523 11 17V14C11 13.4477 10.5523 13 10 13H6Z" />
      <path class="icon_base_light"  d="M6 6C5.44772 6 5 6.44772 5 7V10C5 10.5523 5.44772 11 6 11H10C10.5523 11 11 10.5523 11 10V7C11 6.44772 10.5523 6 10 6H6ZM14 13C13.4477 13 13 13.4477 13 14V17C13 17.5523 13.4477 18 14 18H18C18.5523 18 19 17.5523 19 17V14C19 13.4477 18.5523 13 18 13H14Z" />
      </svg>
      <!-- fill="#6E7687" -->
    </template>

    <template v-else-if="(iconType === 'bell')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="icon_base" d="M17.0645 11.29C17.0645 12.19 17.3348 13.08 17.8415 13.87L18.8325 15.38C19.3055 16.1 18.7199 17 17.7852 17H6.21969C5.28499 17 4.69939 16.1 5.16111 15.38L6.15212 13.86C6.65889 13.08 6.92917 12.19 6.92917 11.28V10C6.92917 8.26 8.04405 6.77 9.6657 6.03C10.0599 5.84 10.3076 5.48 10.3076 5.08C10.3076 4.48 10.8594 4 11.5238 4H12.4698C13.1455 4 13.686 4.48 13.686 5.08C13.686 5.48 13.9338 5.84 14.328 6.02C15.9496 6.77 17.0645 8.26 17.0645 10V11.29ZM11.9968 20C12.9315 20 13.6861 19.33 13.6861 18.5H10.3076C10.3076 19.33 11.0621 20 11.9968 20Z" />
      </svg>
      <!-- fill="#A7AFBE" -->
    </template>

    <template v-else-if="(iconType === 'star')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base" d="M11.0489 4.92705C11.3483 4.00574 12.6517 4.00574 12.9511 4.92705L14.0206 8.21885C14.1545 8.63087 14.5385 8.90983 14.9717 8.90983H18.4329C19.4016 8.90983 19.8044 10.1494 19.0207 10.7188L16.2205 12.7533C15.87 13.0079 15.7234 13.4593 15.8572 13.8713L16.9268 17.1631C17.2261 18.0844 16.1717 18.8506 15.388 18.2812L12.5878 16.2467C12.2373 15.9921 11.7627 15.9921 11.4122 16.2467L8.61204 18.2812C7.82833 18.8506 6.77385 18.0844 7.0732 17.1631L8.14277 13.8713C8.27665 13.4593 8.12999 13.0079 7.7795 12.7533L4.97933 10.7188C4.19562 10.1494 4.59839 8.90983 5.56712 8.90983H9.02832C9.46154 8.90983 9.8455 8.63087 9.97937 8.21885L11.0489 4.92705Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'starFramed')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" fill="#ECE6FF"/>
        <path class="icon_base" d="M11.0489 4.92705C11.3483 4.00574 12.6517 4.00574 12.9511 4.92705L14.0206 8.21885C14.1545 8.63087 14.5385 8.90983 14.9717 8.90983H18.4329C19.4016 8.90983 19.8044 10.1494 19.0207 10.7188L16.2205 12.7533C15.87 13.0079 15.7234 13.4593 15.8572 13.8713L16.9268 17.1631C17.2261 18.0844 16.1717 18.8506 15.388 18.2812L12.5878 16.2467C12.2373 15.9921 11.7627 15.9921 11.4122 16.2467L8.61204 18.2812C7.82833 18.8506 6.77385 18.0844 7.0732 17.1631L8.14277 13.8713C8.27665 13.4593 8.12999 13.0079 7.7795 12.7533L4.97933 10.7188C4.19562 10.1494 4.59839 8.90983 5.56712 8.90983H9.02832C9.46154 8.90983 9.8455 8.63087 9.97937 8.21885L11.0489 4.92705Z" />
        <!-- fill="#7156DC" -->
      </svg>
    </template>

    <template v-else-if="(iconType === 'more')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base_light"  d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14ZM19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" />
        <circle class="icon_base" cx="12" cy="12" r="3" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'moreFramed')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" /><!-- fill="#ECE6FF" opacity="0.5" --> 
        <path class="icon_base_light" fill-rule="evenodd" clip-rule="evenodd" d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14ZM19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" />
        <!-- opacity="0.5" -->
        <circle class="icon_base" cx="12" cy="12" r="3"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'jobProfileQty')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" /> <!-- fill="#ECE6FF" -->
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 9C12.433 9 14 7.433 14 5.5C14 3.567 12.433 2 10.5 2C8.567 2 7 3.567 7 5.5C7 7.433 8.567 9 10.5 9ZM8 10C5.79086 10 4 11.7909 4 14V19C4 20.1046 4.89543 21 6 21H14C15.1046 21 16 20.1046 16 19V14C16 11.7909 14.2091 10 12 10H8Z" />
        <circle class="icon_back" cx="18" cy="17" r="6" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M18 13C17.4477 13 17 13.4477 17 14V16H15C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18H17V20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20V18H21C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16H19V14C19 13.4477 18.5523 13 18 13Z" />
        <!-- 
          fill="#6633FF" 
          fill="#ECE6FF"
          fill="#6633FF"
        -->
      </svg>
    </template>

    <template v-else-if="(iconType === 'formMinus')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="icon_back"  opacity="0.5" width="24" height="24" rx="4" fill="#D4DFF7"/>
      <rect class="icon_base" x="7" y="11" width="10" height="2" rx="1" fill="#4528A4"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'formPlus')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" fill="#D4DFF7"/>
      <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M12 7C11.4477 7 11 7.44772 11 8V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8C13 7.44772 12.5523 7 12 7Z" fill="#4528A4"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'ui_close')">
      <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" />
      <path class="icon_base" d="M16.2427 9.17158C16.6332 8.78106 16.6332 8.1479 16.2427 7.75737C15.8521 7.36685 15.219 7.36685 14.8284 7.75737L12 10.5858L9.17161 7.75737C8.78108 7.36685 8.14792 7.36685 7.75739 7.75737C7.36687 8.1479 7.36687 8.78106 7.75739 9.17158L10.5858 12L7.75737 14.8284C7.36685 15.219 7.36685 15.8521 7.75737 16.2427C8.1479 16.6332 8.78106 16.6332 9.17158 16.2427L12 13.4142L14.8285 16.2427C15.219 16.6332 15.8522 16.6332 16.2427 16.2427C16.6332 15.8521 16.6332 15.219 16.2427 14.8284L13.4142 12L16.2427 9.17158Z" />
      </svg>
    </template>

    <template  v-else-if="(iconType === 'ui_goback')">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4" />
        <path class="icon_base" d="M9.12133 17.1924C9.51185 17.5829 10.145 17.5829 10.5355 17.1924C10.9261 16.8019 10.9261 16.1687 10.5355 15.7782L8.00001 13.2426H17.0711C17.6234 13.2426 18.0711 12.7949 18.0711 12.2426C18.0711 11.6904 17.6234 11.2426 17.0711 11.2426H8.00001L10.5355 8.7071C10.9261 8.31658 10.9261 7.68341 10.5355 7.29289C10.145 6.90236 9.51185 6.90236 9.12133 7.29289L5.58579 10.8284C4.80475 11.6095 4.80475 12.8758 5.58579 13.6568L9.12133 17.1924Z"/>
      </svg>
    </template>

    <template  v-else-if="(iconType === 'ui_arrow')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_ui_arrow" d="M11 16L15 12L11 8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'ui_filters')"> <!-- width="24" height="24" --> 
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" opacity="0.5" width="24" height="24" rx="4"/>
        <path class="icon_baseAlt" fill-rule="evenodd" clip-rule="evenodd" d="M6.5 7C5.67157 7 5 7.67157 5 8.5C5 9.32843 5.67157 10 6.5 10H17.5C18.3284 10 19 9.32843 19 8.5C19 7.67157 18.3284 7 17.5 7H6.5ZM6.5 14C5.67157 14 5 14.6716 5 15.5C5 16.3284 5.67157 17 6.5 17H17.5C18.3284 17 19 16.3284 19 15.5C19 14.6716 18.3284 14 17.5 14H6.5Z" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M6.5 11C7.88071 11 9 9.88071 9 8.5C9 7.11929 7.88071 6 6.5 6C5.11929 6 4 7.11929 4 8.5C4 9.88071 5.11929 11 6.5 11ZM17.5 18C18.8807 18 20 16.8807 20 15.5C20 14.1193 18.8807 13 17.5 13C16.1193 13 15 14.1193 15 15.5C15 16.8807 16.1193 18 17.5 18Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'mnuPlanes_Ov')">

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base_light" d="M13 4H11C10.45 4 10 4.45 10 5V19C10 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V5C14 4.45 13.55 4 13 4Z" />
        <path class="icon_base" d="M7 8.57001H5C4.45 8.57001 4 9.02001 4 9.57001V19C4 19.55 4.45 20 5 20H7C7.55 20 8 19.55 8 19V9.57001C8 9.02001 7.55 8.57001 7 8.57001Z" />
        <path class="icon_dark" d="M19 13.14H17C16.45 13.14 16 13.59 16 14.14V19C16 19.55 16.45 20 17 20H19C19.55 20 20 19.55 20 19V14.14C20 13.59 19.55 13.14 19 13.14Z" />
      </svg>

    </template>
    <template v-else-if="(iconType === 'mnuPlanes_Bs')"> <!-- width="24" height="24" --> 

      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base" d="M20.0875 8.0425C20.9775 7.7425 20.9775 6.4825 20.0875 6.1825L12.1875 3.5525C11.9875 3.4825 11.7675 3.4825 11.5675 3.5525L3.6675 6.1825C2.7775 6.4825 2.7775 7.7425 3.6675 8.0425L11.5675 10.6725C11.7675 10.7425 11.9875 10.7425 12.1875 10.6725L20.0875 8.0425Z" />
        <path class="icon_base_light" d="M11.5675 11.9025L6.6875 10.2725C6.4875 10.2025 6.2675 10.2025 6.0675 10.2725L3.6675 11.0725C2.7775 11.3725 2.7775 12.6325 3.6675 12.9325L11.5675 15.5625C11.7675 15.6325 11.9875 15.6325 12.1875 15.5625L20.0875 12.9325C20.9775 12.6325 20.9775 11.3725 20.0875 11.0725L17.6875 10.2725C17.4875 10.2025 17.2675 10.2025 17.0675 10.2725L12.1875 11.9025C11.9875 11.9625 11.7675 11.9625 11.5675 11.9025Z" />
        <path class="icon_dark" d="M11.5675 16.7825L6.6875 15.1525C6.4875 15.0825 6.2675 15.0825 6.0675 15.1525L3.6675 15.9525C2.7775 16.2525 2.7775 17.5125 3.6675 17.8125L11.5675 20.4425C11.7675 20.5125 11.9875 20.5125 12.1875 20.4425L20.0875 17.8125C20.9775 17.5125 20.9775 16.2525 20.0875 15.9525L17.6875 15.1525C17.4875 15.0825 17.2675 15.0825 17.0675 15.1525L12.1875 16.7825C11.9875 16.8525 11.7675 16.8525 11.5675 16.7825Z" />
      </svg>

    </template>

    <template v-else-if="(iconType === 'mnuPlanes_Se')"> <!-- width="24" height="24" --> 

      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_dark" fill-rule="evenodd" clip-rule="evenodd" d="M8 3.5C8 3.22386 8.22386 3 8.5 3C8.77614 3 9 3.22386 9 3.5V4.5C9 4.77614 8.77614 5 8.5 5C8.22386 5 8 4.77614 8 4.5V3.5ZM14 4H10V4.5C10 5.32843 9.32843 6 8.5 6C7.67157 6 7 5.32843 7 4.5V4H5C4.44772 4 4 4.44772 4 5V7H20V5C20 4.44772 19.5523 4 19 4H17V4.5C17 5.32843 16.3284 6 15.5 6C14.6716 6 14 5.32843 14 4.5V4ZM15.5 3C15.2239 3 15 3.22386 15 3.5V4.5C15 4.77614 15.2239 5 15.5 5C15.7761 5 16 4.77614 16 4.5V3.5C16 3.22386 15.7761 3 15.5 3Z" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M20 8H4V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V8ZM7 10H9V12H7V10ZM7 14H9V16H7V14ZM13 10H11V12H13V10ZM11 14H13V16H11V14ZM17 10H15V12H17V10ZM15 14H17V16H15V14Z" />
      </svg>

    </template>
    <template v-else-if="(iconType === 'mnuPlanes_Co')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_dark" d="M16 9.27V5.69C16 5.37 15.83 5.07 15.55 4.91L12.45 3.12C12.17 2.96 11.83 2.96 11.55 3.12L8.45 4.91C8.17 5.07 8 5.37 8 5.69V9.27C8 9.59 8.17 9.89 8.45 10.05L11.55 11.84C11.83 12 12.17 12 12.45 11.84L15.55 10.05C15.83 9.89 16 9.59 16 9.27Z" />
        <path class="icon_base_light" d="M10.55 12.91L7.45 11.12C7.17 10.96 6.83 10.96 6.55 11.12L3.45 12.91C3.17 13.07 3 13.37 3 13.69V17.27C3 17.59 3.17 17.89 3.45 18.05L6.55 19.84C6.83 20 7.17 20 7.45 19.84L10.55 18.05C10.83 17.89 11 17.59 11 17.27V13.69C11 13.37 10.83 13.07 10.55 12.91Z" />
        <path class="icon_base" d="M20.55 12.91L17.45 11.12C17.17 10.96 16.83 10.96 16.55 11.12L13.45 12.91C13.17 13.07 13 13.37 13 13.69V17.27C13 17.59 13.17 17.89 13.45 18.05L16.55 19.84C16.83 20 17.17 20 17.45 19.84L20.55 18.05C20.83 17.89 21 17.59 21 17.27V13.69C21 13.37 20.83 13.07 20.55 12.91Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'sectionJP_general')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="icon_back" width="24" height="24" rx="2" />
      <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M9 7C9 5.89543 9.89543 5 11 5H13C14.1046 5 15 5.89543 15 7H19C19.5523 7 20 7.44772 20 8V11H17V10H15V11H9V10H7V11H4V8C4 7.44772 4.44772 7 5 7H9ZM10 7H14C14 6.44772 13.5523 6 13 6H11C10.4477 6 10 6.44772 10 7ZM15 13V12H9V13H7V12H4V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V12H17V13H15Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'sectionJP_califica')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" width="24" height="24" rx="2" />
        <path class="icon_base"  d="M7.70457 17.9543L8.40995 10.9005C8.46107 10.3893 8.89124 10 9.40499 10H14.595C15.1088 10 15.5389 10.3893 15.59 10.9005L16.2954 17.9543C16.3784 18.7843 15.4686 19.3451 14.7644 18.898L12.536 17.4832C12.2089 17.2755 11.7911 17.2755 11.464 17.4832L9.23561 18.898C8.53141 19.3451 7.62157 18.7843 7.70457 17.9543Z" />
        <circle class="icon_base"  cx="12" cy="8.5" r="5.5" />
        <circle class="icon_back" cx="12" cy="8.5" r="3.5" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'sectionJP_uniforme')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" width="24" height="24" rx="2" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M2.85693 4C2.85693 3.44771 3.30465 3 3.85693 3H6.28551V9.85714H3.85693C3.30465 9.85714 2.85693 9.40943 2.85693 8.85714V4ZM6.28553 3H8.57125L10.5856 5.01436C11.3667 5.79541 12.633 5.79541 13.414 5.01436L15.4284 3H17.7141H20.1427C20.695 3 21.1427 3.44772 21.1427 4V8.85714C21.1427 9.40943 20.695 9.85714 20.1427 9.85714H17.7141V19C17.7141 19.5523 17.2664 20 16.7141 20H7.28553C6.73325 20 6.28553 19.5523 6.28553 19V3ZM16.5712 7.57143H13.1426V8.71429H16.5712V7.57143Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'sectionJP_habilidad')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="icon_back" width="24" height="24" rx="2" />
      <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M13.9509 3.1754L6.02984 11.3623C5.43712 11.9749 5.83352 13.0007 6.68416 13.0555L9.65394 13.2471L7.7742 20.2623C7.50713 21.2591 8.7413 21.9581 9.4588 21.2165L17.3798 13.0297C17.9726 12.417 17.5762 11.3912 16.7255 11.3364L13.7557 11.1449L15.6355 4.12957C15.9026 3.13286 14.6684 2.43382 13.9509 3.1754Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'sectionJP_documento')"> <!-- width="24" height="24" --> 
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_back" width="24" height="24" rx="2" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4H12.5V7C12.5 8.10457 13.3954 9 14.5 9H17.5V18C17.5 18.5523 17.0523 19 16.5 19H7.5C6.94771 19 6.5 18.5523 6.5 18V5C6.5 4.44772 6.94772 4 7.5 4ZM13.5 7V4L17.5 8H14.5C13.9477 8 13.5 7.55228 13.5 7Z" />
      </svg>
    </template>


    <!-- Canna front --> 
    <template v-else-if="(iconType === 'cannav2_menu_start')">

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_baseLight" opacity="0.3" d="M4 11.8757C4 11.3175 4.23328 10.7847 4.64344 10.4061L9.14344 6.25221C9.90956 5.54502 11.0904 5.54502 11.8566 6.25221L16.3566 10.4061C16.7667 10.7847 17 11.3175 17 11.8757V17C17 18.1046 16.1046 19 15 19H6C4.89543 19 4 18.1046 4 17V11.8757Z" />
        <path class="icon_base" d="M6 9.87566C6 9.31747 6.23328 8.78467 6.64344 8.40606L11.1434 4.25221C11.9096 3.54502 13.0904 3.54502 13.8566 4.25221L18.3566 8.40606C18.7667 8.78467 19 9.31747 19 9.87566V15C19 16.1046 18.1046 17 17 17H8C6.89543 17 6 16.1046 6 15V9.87566Z" />
      </svg>

    </template>

    <template v-else-if="(iconType === 'cannav2_menu_docs')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_baseLight" opacity="0.3" d="M11.5 6H6.5C5.94772 6 5.5 6.44772 5.5 7V20C5.5 20.5523 5.94771 21 6.5 21H15.5C16.0523 21 16.5 20.5523 16.5 20V11H13.5C12.3954 11 11.5 10.1046 11.5 9V6Z" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M8.5 4H13.5V7C13.5 8.10457 14.3954 9 15.5 9H18.5V18C18.5 18.5523 18.0523 19 17.5 19H8.5C7.94771 19 7.5 18.5523 7.5 18V5C7.5 4.44772 7.94772 4 8.5 4ZM14.5 7V4L18.5 8H15.5C14.9477 8 14.5 7.55228 14.5 7Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_menu_videos')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="icon_baseLight" opacity="0.3" x="3" y="7" width="16" height="12" rx="2" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M7 5C5.89543 5 5 5.89543 5 7V15C5 16.1046 5.89543 17 7 17H19C20.1046 17 21 16.1046 21 15V7C21 5.89543 20.1046 5 19 5H7ZM11 14.4641L17 11L11 7.5359L11 14.4641Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_menu_vacantes')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_baseLight" fill-rule="evenodd" clip-rule="evenodd" d="M9.24994 14.6798C8.27779 14.6069 7.25789 14.4809 6.22543 14.3158C5.63072 14.2207 5.07113 14.1187 4.56323 14.0169C4.38574 13.9813 4.22742 13.9482 4.09045 13.9187C4.05566 13.9111 4.02539 13.9045 4 13.8989V9.49862C4 9.0161 4.39197 8.62498 4.8739 8.62498H7.93746V6.87415C7.93746 6.39137 8.32993 6 8.81502 6H13.185C13.6696 6 14.0625 6.39293 14.0625 6.87415V8.62498H17.1261C17.6088 8.62498 18 9.01657 18 9.49862V13.8989C17.9745 13.9045 17.9443 13.9111 17.9094 13.9187C17.7721 13.9482 17.6134 13.9813 17.4356 14.0169C16.9267 14.1187 16.3659 14.2207 15.7702 14.3158C14.739 14.4804 13.7206 14.6062 12.75 14.6792V14.3122C12.75 14.0689 12.5547 13.8749 12.3139 13.8749H9.68622C9.4483 13.8749 9.25006 14.0707 9.25006 14.3122L9.24994 14.6798ZM9.24994 15.1187V15.6249C9.24994 15.8682 9.44525 16.0623 9.68609 16.0623H12.3138C12.5517 16.0623 12.7499 15.8665 12.7499 15.6249V15.1181C13.7495 15.0445 14.797 14.9156 15.8571 14.7464C16.4601 14.6501 17.0278 14.5469 17.5435 14.4437C17.7133 14.4097 17.866 14.378 17.9999 14.3492V19.126C17.9999 19.6085 17.6108 19.9997 17.1241 19.9997H4.87582C4.39217 19.9997 4.00011 19.6081 4.00011 19.126V14.3492C4.13365 14.3779 4.28587 14.4096 4.45517 14.4435C4.96969 14.5468 5.53635 14.65 6.13837 14.7463C7.19977 14.9159 8.2487 15.0451 9.25003 15.1186L9.24994 15.1187ZM8.81244 7.08942V8.62481H13.1874V7.08942C13.1874 6.96894 13.0875 6.87482 12.9644 6.87482H9.03535C8.91194 6.87482 8.81234 6.97089 8.81234 7.08942H8.81244Z" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M11.2499 12.6798C10.2778 12.6069 9.25789 12.4809 8.22543 12.3158C7.63072 12.2207 7.07113 12.1187 6.56323 12.0169C6.38574 11.9813 6.22742 11.9482 6.09045 11.9187C6.05566 11.9111 6.02539 11.9045 6 11.8989V7.49862C6 7.0161 6.39197 6.62498 6.8739 6.62498H9.93746V4.87415C9.93746 4.39137 10.3299 4 10.815 4H15.185C15.6696 4 16.0625 4.39293 16.0625 4.87415V6.62498H19.1261C19.6088 6.62498 20 7.01657 20 7.49862V11.8989C19.9745 11.9045 19.9443 11.9111 19.9094 11.9187C19.7721 11.9482 19.6134 11.9813 19.4356 12.0169C18.9267 12.1187 18.3659 12.2207 17.7702 12.3158C16.739 12.4804 15.7206 12.6062 14.75 12.6792V12.3122C14.75 12.0689 14.5547 11.8749 14.3139 11.8749H11.6862C11.4483 11.8749 11.2501 12.0707 11.2501 12.3122L11.2499 12.6798ZM11.2499 13.1187V13.6249C11.2499 13.8682 11.4452 14.0623 11.6861 14.0623H14.3138C14.5517 14.0623 14.7499 13.8665 14.7499 13.6249V13.1181C15.7495 13.0445 16.797 12.9156 17.8571 12.7464C18.4601 12.6501 19.0278 12.5469 19.5435 12.4437C19.7133 12.4097 19.866 12.378 19.9999 12.3492V17.126C19.9999 17.6085 19.6108 17.9997 19.1241 17.9997H6.87582C6.39217 17.9997 6.00011 17.6081 6.00011 17.126V12.3492C6.13365 12.3779 6.28587 12.4096 6.45517 12.4435C6.96969 12.5468 7.53635 12.65 8.13837 12.7463C9.19977 12.9159 10.2487 13.0451 11.25 13.1186L11.2499 13.1187ZM10.8124 5.08942V6.62481H15.1874V5.08942C15.1874 4.96894 15.0875 4.87482 14.9644 4.87482H11.0354C10.9119 4.87482 10.8123 4.97089 10.8123 5.08942H10.8124Z" />
      </svg>

      <!-- fill="#F8D5B3"  fill="#F2800D" -->
    </template>

    <template v-else-if="(iconType === 'cannav2_menu_solicitud')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_base" d="M20.8881 10.0937C20.2167 5.52259 16.2797 2 11.5263 2C6.7729 2 2.83589 5.52259 2.16453 10.0978C1.46001 10.6739 1 11.5441 1 12.5263C1 13.8359 1.79569 14.959 2.93121 15.4356C4.17033 18.1127 6.63199 20.1019 9.59097 20.7236C9.91422 21.4737 10.656 22 11.5263 22C12.6908 22 13.6316 21.0593 13.6316 19.8947C13.6316 18.7302 12.6908 17.7895 11.5263 17.7895C10.8342 17.7895 10.2292 18.1252 9.84376 18.639C6.59055 17.8765 4.1579 14.9548 4.1579 11.4737V11.4695C4.1579 8.34065 6.10153 5.46871 9.07708 4.49896C14.1703 2.84128 18.8947 6.61252 18.8947 11.4695V15.2242C18.8947 15.4894 19.1392 15.6842 19.4003 15.6428C20.9047 15.4024 22.0526 14.097 22.0526 12.5263C22.0526 11.5441 21.5926 10.6739 20.8881 10.0937Z" />
        <path class="icon_base" d="M8.36855 9.36845C8.9499 9.36845 9.42118 8.89717 9.42118 8.31582C9.42118 7.73446 8.9499 7.26318 8.36855 7.26318C7.7872 7.26318 7.31592 7.73446 7.31592 8.31582C7.31592 8.89717 7.7872 9.36845 8.36855 9.36845Z" />
        <path class="icon_base" d="M14.6842 9.36845C15.2656 9.36845 15.7369 8.89717 15.7369 8.31582C15.7369 7.73446 15.2656 7.26318 14.6842 7.26318C14.1029 7.26318 13.6316 7.73446 13.6316 8.31582C13.6316 8.89717 14.1029 9.36845 14.6842 9.36845Z" />
        <path class="icon_base" d="M13.76 12.6548C12.5292 13.8856 10.5234 13.8856 9.29255 12.6548C8.88228 12.2445 8.21506 12.2445 7.80478 12.6548C7.3945 13.065 7.3945 13.7323 7.80478 14.1425C8.83254 15.1703 10.1794 15.68 11.5263 15.68C12.8732 15.68 14.22 15.1662 15.2478 14.1425C15.6581 13.7323 15.6581 13.065 15.2478 12.6548C14.8375 12.2445 14.1703 12.2445 13.76 12.6548Z" />      
      </svg>
      <!-- fill="#7156DC"  -->
    </template>

    <template v-else-if="(iconType === 'cannav2_menu_complete')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="icon_base" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" />
      <path fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M16.5882 7.19127C17.0348 7.51611 17.1336 8.14153 16.8087 8.58818L11.7178 15.5882C11.5527 15.8153 11.2996 15.9625 11.0205 15.9938C10.7414 16.0251 10.462 15.9375 10.2506 15.7526L7.34151 13.2071C6.92587 12.8434 6.88375 12.2117 7.24744 11.7961C7.61112 11.3804 8.24288 11.3383 8.65852 11.702L10.744 13.5268L15.1913 7.41184C15.5161 6.96519 16.1415 6.86644 16.5882 7.19127Z" />
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_settings_password')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="icon_baseLight" opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M13 9V11H9V9C9 7.89543 9.89543 7 11 7C12.1046 7 13 7.89543 13 9ZM7 11V9C7 6.79086 8.79086 5 11 5C13.2091 5 15 6.79086 15 9V11H16C16.5523 11 17 11.4477 17 12V19C17 19.5523 16.5523 20 16 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z" />
        <path class="icon_base" fill-rule="evenodd" clip-rule="evenodd" d="M15 7V9H11V7C11 5.89543 11.8954 5 13 5C14.1046 5 15 5.89543 15 7ZM9 9V7C9 4.79086 10.7909 3 13 3C15.2091 3 17 4.79086 17 7V9H18C18.5523 9 19 9.44772 19 10V17C19 17.5523 18.5523 18 18 18H8C7.44772 18 7 17.5523 7 17V10C7 9.44772 7.44772 9 8 9H9Z" />
      </svg>
    </template>

    <!-- canna dashboard --> 
    <template v-else-if="(iconType === 'cannav2_dash_phone')">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" fill="white"/>
        <rect x="9.16675" y="7.5" width="18.3333" height="30" rx="2" fill="#A8B4F0"/>
        <rect x="12.1667" y="4.66667" width="18.1667" height="30.3636" rx="1.5" fill="white" stroke="black"/>
        <rect x="15" y="9.16667" width="12.5" height="21.4333" rx="1" fill="#A8B4F0"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="15" y="9" width="13" height="22">
        <rect x="15" y="9.16667" width="12.5" height="21.4333" rx="1" fill="#A8B4F0"/>
        </mask>
        <g mask="url(#mask0)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 9.16667C15.4477 9.16667 15 9.61439 15 10.1667V16.9713L22.3481 9.16667H16ZM26.4997 30.5952C27.052 30.5952 27.4997 30.1475 27.4997 29.5952V17.7659L15.6434 30.5952H26.4997ZM26.5 9.16667C27.0523 9.16667 27.5 9.61439 27.5 10.1667V15.5338L15 29.0597V21.1822L26.1043 9.16667H26.5Z" fill="black"/>
        </g>
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_dash_mail')">

      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" fill="white"/>
        <path d="M2.63776 21.9174L13.1378 15.6174C13.7711 15.2374 14.5624 15.2374 15.1957 15.6174L25.6957 21.9174C26.2982 22.2788 26.6667 22.9299 26.6667 23.6324V33C26.6667 34.1046 25.7713 35 24.6667 35H3.66675C2.56218 35 1.66675 34.1046 1.66675 33V23.6324C1.66675 22.9299 2.03534 22.2788 2.63776 21.9174Z" fill="#A8B4F0"/>
        <path d="M7.40892 14.5052L20.7422 5.61633C21.3021 5.2431 22.0314 5.24311 22.5912 5.61633L35.9246 14.5052C36.3882 14.8143 36.6667 15.3347 36.6667 15.892V30C36.6667 30.9205 35.9206 31.6667 35.0001 31.6667H8.33341C7.41294 31.6667 6.66675 30.9205 6.66675 30V15.892C6.66675 15.3347 6.94525 14.8143 7.40892 14.5052Z" fill="#A8B4F0"/>
        <path d="M6.66675 30V15L20.7422 24.3837C21.3021 24.7569 22.0314 24.7569 22.5912 24.3837L36.6667 15V30C36.6667 30.9205 35.9206 31.6667 35.0001 31.6667H8.33341C7.41294 31.6667 6.66675 30.9205 6.66675 30Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1288 6.3097C21.8489 6.12309 21.4843 6.12309 21.2043 6.3097L7.871 15.1986C7.63917 15.3531 7.49992 15.6133 7.49992 15.892V30C7.49992 30.4602 7.87302 30.8333 8.33325 30.8333H34.9999C35.4602 30.8333 35.8333 30.4602 35.8333 30V15.892C35.8333 15.6133 35.694 15.3531 35.4622 15.1986L22.1288 6.3097ZM20.2798 4.92295C21.1196 4.36312 22.2136 4.36312 23.0533 4.92295L36.3867 13.8118C37.0822 14.2755 37.4999 15.0561 37.4999 15.892V30C37.4999 31.3807 36.3806 32.5 34.9999 32.5H8.33325C6.95254 32.5 5.83325 31.3807 5.83325 30V15.892C5.83325 15.0561 6.25101 14.2755 6.9465 13.8118L20.2798 4.92295Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27337 14.2653C6.54433 14.1202 6.87312 14.1361 7.12884 14.3066L21.2043 23.6903C21.4843 23.8769 21.8489 23.8769 22.1288 23.6903L36.2043 14.3066C36.46 14.1361 36.7888 14.1202 37.0598 14.2653C37.3308 14.4103 37.4999 14.6927 37.4999 15V30C37.4999 31.3807 36.3806 32.5 34.9999 32.5H8.33325C6.95254 32.5 5.83325 31.3807 5.83325 30V15C5.83325 14.6927 6.00241 14.4103 6.27337 14.2653ZM7.49992 16.5571V30C7.49992 30.4602 7.87302 30.8333 8.33325 30.8333H34.9999C35.4602 30.8333 35.8333 30.4602 35.8333 30V16.5571L23.0533 25.077C22.2136 25.6369 21.1196 25.6369 20.2798 25.077L7.49992 16.5571Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2558 22.2559L7.25584 32.2559L6.07733 31.0774L16.0773 21.0774L17.2558 22.2559Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.0773 32.2559L26.0773 22.2559L27.2558 21.0774L37.2558 31.0774L36.0773 32.2559Z" fill="black"/>
        <path d="M11.6667 17.4414V10C11.6667 9.07953 12.4129 8.33334 13.3334 8.33334H30.0001C30.9206 8.33334 31.6667 9.07953 31.6667 10V17.4414C31.6667 17.9986 31.3882 18.519 30.9246 18.8281L22.5913 24.3837C22.0314 24.7569 21.3021 24.7569 20.7422 24.3837L12.4089 18.8281C11.9452 18.519 11.6667 17.9986 11.6667 17.4414Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 9.16667C12.873 9.16667 12.4999 9.53976 12.4999 10V17.4414C12.4999 17.72 12.6392 17.9802 12.871 18.1347L21.2043 23.6903C21.4843 23.8769 21.8489 23.8769 22.1288 23.6903L30.4622 18.1347C30.694 17.9802 30.8333 17.72 30.8333 17.4414V10C30.8333 9.53976 30.4602 9.16667 29.9999 9.16667H13.3333ZM10.8333 10C10.8333 8.61929 11.9525 7.5 13.3333 7.5H29.9999C31.3806 7.5 32.4999 8.61929 32.4999 10V17.4414C32.4999 18.2772 32.0822 19.0578 31.3867 19.5215L23.0533 25.077C22.2136 25.6369 21.1196 25.6369 20.2798 25.077L11.9465 19.5215C11.251 19.0578 10.8333 18.2772 10.8333 17.4414V10Z" fill="black"/>
        <path d="M20.8334 20C23.1346 20 25.0001 18.1345 25.0001 15.8333C25.0001 13.5321 23.1346 11.6667 20.8334 11.6667C18.5322 11.6667 16.6667 13.5321 16.6667 15.8333C16.6667 18.1345 18.5322 20 20.8334 20Z" fill="#A8B4F0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3177 10.1878C26.6993 10.5033 26.7806 11.1061 26.4994 11.5341L22.2899 17.9418C22.1415 18.1676 21.9128 18.3096 21.6634 18.3306C21.4141 18.3517 21.169 18.2497 20.9922 18.0513L18.5849 15.3506C18.2497 14.9746 18.2497 14.365 18.5849 13.989C18.92 13.6129 19.4634 13.6129 19.7986 13.989L21.5002 15.898L25.1177 10.3916C25.3989 9.96359 25.9362 9.87234 26.3177 10.1878Z" fill="#24222A"/>
      </svg>

    </template>

    <template v-else-if="(iconType === 'cannav2_dash_personal')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.3844 12.1656C26.3844 15.6031 23.9063 21.2875 20.4657 21.2875C17.025 21.2875 14.55 15.6063 14.55 12.1656C14.55 8.725 17.025 5.9375 20.4657 5.9375C23.9063 5.9375 26.3844 8.725 26.3844 12.1656Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M16.5188 21.9219C16.466 21.7649 16.3532 21.6352 16.2049 21.5614C16.0567 21.4876 15.8852 21.4756 15.7281 21.5281C14.2531 22.025 10.5 23.3312 9.92189 23.9094C9.34377 24.4875 8.20314 30.0812 7.85939 31.8625C7.83083 32.0127 7.85831 32.1681 7.93663 32.2994C8.01495 32.4307 8.13867 32.5288 8.28439 32.575L20.4719 36.4V33.75C20.4724 33.6821 20.4619 33.6145 20.4406 33.55L16.5188 21.9219Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M20.4656 33.6406L15.5593 28.2938C15.461 28.1871 15.4029 28.0495 15.395 27.9047C15.387 27.7599 15.4296 27.6168 15.5156 27.5L15.9624 26.875C16.0589 26.7417 16.0989 26.5757 16.0738 26.413C16.0486 26.2504 15.9603 26.1042 15.8281 26.0063L14.5781 25.0688C14.4511 24.9738 14.365 24.8341 14.3371 24.6781C14.3093 24.522 14.3418 24.3611 14.4281 24.2281L16.6343 21.3125L16.5624 18.7875" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M20.4656 33.6406L25.3718 28.2938C25.4705 28.1875 25.5292 28.0501 25.5378 27.9053C25.5463 27.7605 25.5042 27.6172 25.4187 27.5L24.9718 26.875C24.875 26.7421 24.8345 26.5763 24.859 26.4137C24.8836 26.251 24.9713 26.1047 25.1031 26.0063L26.3531 25.0688C26.48 24.9738 26.5662 24.8341 26.594 24.6781C26.6219 24.522 26.5894 24.3611 26.5031 24.2281L24.3156 21.3125L24.375 18.5875" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M24.4126 21.9219C24.4653 21.7649 24.5782 21.6352 24.7264 21.5614C24.8747 21.4876 25.0462 21.4756 25.2032 21.5281C26.6782 22.025 30.4313 23.3312 31.0095 23.9094C31.5876 24.4875 32.7313 30.0812 33.0751 31.8625C33.1035 32.0131 33.0756 32.1689 32.9967 32.3002C32.9177 32.4316 32.7933 32.5294 32.647 32.575L20.4595 36.4V33.75C20.46 33.6819 20.4716 33.6143 20.4938 33.55L24.4126 21.9219Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M16.3218 21.3313L18.178 23.1906C18.4782 23.4915 18.8347 23.7302 19.2272 23.893C19.6198 24.0559 20.0406 24.1397 20.4655 24.1397C20.8905 24.1397 21.3113 24.0559 21.7038 23.893C22.0963 23.7302 22.4529 23.4915 22.753 23.1906L24.628 21.3313" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M25.8156 7.60313C25.8156 7.60313 23.75 10.9375 20.8625 12.5188C21.0417 11.7088 21.0417 10.8694 20.8625 10.0594C20.8625 10.0594 17.1625 13.2906 14.1094 14.4719C14.1094 14.4719 12.2969 9.225 16.3219 5.9375C20.3469 2.65 25.85 5.84063 25.8156 7.60313Z" fill="black"/>
        <path d="M24.0625 8.91563C24.0625 8.91563 24.5781 12.3531 26.3844 14.4719C26.3844 14.4719 29.3094 10.4094 25.8156 7.59688L24.0625 8.91563Z" fill="black"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_dash_docs')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" fill="white"/>
        <path d="M27.3445 37.0208C27.3445 37.5731 26.8968 38.0208 26.3445 38.0208H6.98828C6.436 38.0208 5.98828 37.5731 5.98828 37.0208V9.64584C5.98828 9.09355 6.436 8.64584 6.98828 8.64584H20.9914L27.3445 14.8865V37.0208Z" fill="#A8B4F0"/>
        <path d="M30.6778 33.0208C30.6778 33.9413 29.9316 34.6875 29.0111 34.6875H10.9882C10.0677 34.6875 9.32153 33.9413 9.32153 33.0208V6.97917C9.32153 6.05869 10.0677 5.3125 10.9882 5.3125H24.3247L30.6778 11.5531V33.0208Z" fill="white"/>
        <path d="M19.4264 14.9096L17.4941 13.2329L15.5618 14.9096C15.5083 14.9557 15.4453 14.983 15.3798 14.9883C15.3143 14.9937 15.2489 14.9769 15.1908 14.94C15.1327 14.903 15.0841 14.8472 15.0504 14.7787C15.0168 14.7103 14.9993 14.6318 15 14.5521V5.71498C15 5.52535 15.062 5.3435 15.1723 5.20941C15.2826 5.07533 15.4322 5 15.5882 5H19.4117C19.5677 5 19.7173 5.07533 19.8276 5.20941C19.9379 5.3435 19.9999 5.52535 19.9999 5.71498V14.5556C20.0017 14.6375 19.9844 14.7183 19.9499 14.7887C19.9155 14.859 19.8653 14.9161 19.8052 14.9531C19.7452 14.9902 19.6777 15.0058 19.6106 14.9981C19.5435 14.9904 19.4796 14.9597 19.4264 14.9096Z" fill="#A8B4F0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9883 6.14583C10.528 6.14583 10.1549 6.51893 10.1549 6.97916V33.0208C10.1549 33.4811 10.528 33.8542 10.9883 33.8542H29.0112C29.4714 33.8542 29.8445 33.4811 29.8445 33.0208V11.9027L23.9839 6.14583H10.9883ZM8.48828 6.97916C8.48828 5.59845 9.60757 4.47916 10.9883 4.47916H24.3247C24.5432 4.47916 24.7529 4.56493 24.9087 4.718L31.2618 10.9586C31.4213 11.1153 31.5112 11.3295 31.5112 11.5531V33.0208C31.5112 34.4015 30.3919 35.5208 29.0112 35.5208H10.9883C9.60757 35.5208 8.48828 34.4015 8.48828 33.0208V6.97916Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7027 17.3291C13.2425 17.3291 12.8694 17.7022 12.8694 18.1625C12.8694 18.6227 13.2425 18.9958 13.7027 18.9958H26.2965C26.7567 18.9958 27.1298 18.6227 27.1298 18.1625C27.1298 17.7022 26.7567 17.3291 26.2965 17.3291H13.7027ZM13.7027 20.9917C13.2425 20.9917 12.8694 21.3648 12.8694 21.825C12.8694 22.2852 13.2425 22.6583 13.7027 22.6583H26.2965C26.7567 22.6583 27.1298 22.2852 27.1298 21.825C27.1298 21.3648 26.7567 20.9917 26.2965 20.9917H13.7027ZM12.8694 25.4844C12.8694 25.0242 13.2425 24.6511 13.7027 24.6511H26.2965C26.7567 24.6511 27.1298 25.0242 27.1298 25.4844C27.1298 25.9446 26.7567 26.3177 26.2965 26.3177H13.7027C13.2425 26.3177 12.8694 25.9446 12.8694 25.4844ZM13.7027 28.3104C13.2425 28.3104 12.8694 28.6835 12.8694 29.1437C12.8694 29.604 13.2425 29.9771 13.7027 29.9771H26.2965C26.7567 29.9771 27.1298 29.604 27.1298 29.1437C27.1298 28.6835 26.7567 28.3104 26.2965 28.3104H13.7027Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0025 4.54405C24.312 4.4142 24.6693 4.48278 24.9088 4.71802L31.2619 10.9587C31.5033 11.1958 31.5773 11.5554 31.4492 11.8686C31.3211 12.1818 31.0163 12.3865 30.6779 12.3865H24.3248C23.8646 12.3865 23.4915 12.0134 23.4915 11.5531V5.31252C23.4915 4.97682 23.6929 4.6739 24.0025 4.54405ZM25.1581 7.29922V10.7198H28.6404L25.1581 7.29922Z" fill="black"/>
        <path d="M17.7596 13.2429L15.8273 11.5663L13.895 13.2429C13.8415 13.289 13.7785 13.3163 13.7131 13.3217C13.6476 13.327 13.5822 13.3103 13.5241 13.2733C13.4659 13.2363 13.4173 13.1805 13.3837 13.112C13.35 13.0436 13.3326 12.9652 13.3333 12.8854V4.04831C13.3333 3.85869 13.3952 3.67683 13.5056 3.54275C13.6159 3.40866 13.7655 3.33334 13.9215 3.33334H17.7449C17.9009 3.33334 18.0505 3.40866 18.1608 3.54275C18.2711 3.67683 18.3331 3.85869 18.3331 4.04831V12.889C18.335 12.9708 18.3176 13.0516 18.2832 13.122C18.2487 13.1924 18.1986 13.2494 18.1385 13.2865C18.0784 13.3235 18.0109 13.3391 17.9438 13.3314C17.8768 13.3237 17.8129 13.293 17.7596 13.2429Z" fill="black"/>
      </svg>
    </template>

    <template v-else-if="(iconType === 'cannav2_docs_attachment')">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" fill="white"/>
        <path d="M29.0115 35.3542C29.0115 35.9064 28.5638 36.3542 28.0115 36.3542H8.65527C8.10299 36.3542 7.65527 35.9064 7.65527 35.3542V7.97916C7.65527 7.42687 8.10299 6.97916 8.65527 6.97916H22.6584L29.0115 13.2198V35.3542Z" fill="#A8B4F0"/>
        <path d="M32.3445 32.0208C32.3445 32.5731 31.8968 33.0208 31.3445 33.0208H11.9883C11.436 33.0208 10.9883 32.5731 10.9883 32.0208V4.64584C10.9883 4.09356 11.436 3.64584 11.9883 3.64584H25.9914L32.3445 9.88647V32.0208Z" fill="white" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.3443 9.88647H26.4912C26.2151 9.88647 25.9912 9.66261 25.9912 9.38647V3.64584L32.3443 9.88647Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.3691 20H27.9629" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M15.3691 23.3333H27.9629" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M15.3691 26.6667H27.9629" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
        <rect x="16.667" y="10" width="6.66667" height="6.66667" fill="#A8B4F0"/>
        <rect x="15.5" y="8.83334" width="5.66667" height="5.66667" stroke="#24222A"/>
      </svg>
    </template>

    <!-- Vacantes Item -->
    <template v-else-if="(iconType === 'vacante_Listitem')">
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" transform="translate(0 0.5)" fill="white"/>
        <rect x="4" y="11.6953" width="31.1644" height="22.4426" rx="1.5" fill="#A8B4F0"/>
        <rect x="5.27637" y="11.1641" width="30.2071" height="21.8044" rx="1" fill="white"/>
        <path d="M33.6703 33.2742H7.15398C5.8839 33.2742 4.85059 32.2409 4.85059 30.9712V13.0373C4.85059 11.7676 5.88388 10.7344 7.15398 10.7344H33.6703C34.9404 10.7344 35.9737 11.7677 35.9737 13.0373V30.9712C35.9737 32.2405 34.9404 33.2742 33.6703 33.2742ZM7.15398 11.8176C6.48091 11.8176 5.93368 12.3648 5.93368 13.0375V30.9713C5.93368 31.644 6.48086 32.1912 7.15398 32.1912H33.6703C34.3433 32.1912 34.8906 31.644 34.8906 30.9713V13.0375C34.8906 12.3648 34.3434 11.8176 33.6703 11.8176H7.15398Z" fill="black"/>
        <path d="M25.4451 11.7898C25.1459 11.7898 24.9037 11.5476 24.9037 11.2485V8.80297C24.9037 8.13031 24.3565 7.58309 23.6838 7.58309H17.1932C16.5206 7.58309 15.9734 8.13028 15.9734 8.80297V11.2485C15.9734 11.5476 15.7311 11.7898 15.432 11.7898C15.1328 11.7898 14.8906 11.5476 14.8906 11.2485V8.80297C14.8906 7.53321 15.9239 6.5 17.1936 6.5H23.6839C24.9536 6.5 25.9868 7.53329 25.9868 8.80297V11.2485C25.9864 11.548 25.7442 11.7898 25.445 11.7898H25.4451Z" fill="black"/>
        <path d="M18.9752 21.7248H13.9159C10.4221 21.7248 7.09867 20.0566 5.01587 17.2597C4.93028 17.1642 4.87793 17.0383 4.87793 16.9007C4.87793 16.6016 5.11517 16.3594 5.41473 16.3594H5.42387C5.59588 16.3594 5.7575 16.4408 5.85972 16.5792C7.73724 19.1232 10.7495 20.6417 13.9163 20.6417H18.9755C19.2747 20.6417 19.5169 20.8839 19.5169 21.1831C19.5165 21.4826 19.2743 21.7249 18.9751 21.7249L18.9752 21.7248Z" fill="black"/>
        <path d="M26.962 21.7267H21.9115C21.6123 21.7267 21.3701 21.4845 21.3701 21.1853C21.3701 20.8862 21.6123 20.6439 21.9115 20.6439H26.962C30.1288 20.6439 33.1411 19.1254 35.0186 16.5814C35.2978 16.2008 36.0004 16.4318 36.0004 16.903C36.0004 17.0517 35.941 17.1863 35.8454 17.2844C33.7618 20.0673 30.4466 21.7267 26.962 21.7267L26.962 21.7267Z" fill="black"/>
        <rect x="19.3164" y="19.6719" width="2.23362" height="2.97816" fill="#A8B4F0"/>
        <path d="M21.9059 23.6274H18.9701C18.6709 23.6274 18.4287 23.3852 18.4287 23.086V19.2836C18.4287 18.9844 18.6709 18.7422 18.9701 18.7422H21.9059C22.2051 18.7422 22.4473 18.9844 22.4473 19.2836V23.086C22.4473 23.3852 22.2051 23.6274 21.9059 23.6274ZM19.5115 22.5442H21.3645V19.8245H19.5115V22.5442Z" fill="black"/>
      </svg>
    </template>


    <template v-if="hasQMenu">
      <slot name="menu">
        
      </slot>
    </template>
  </q-icon>
</template>
<script>
export default {
  name: 'canna_icons',
  props: {
    iconType: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: String,
      required: true,
      default: '24px'
    },
    cls: {
      type: String,
      required: true,
      default: ''
    },
    disabledCls: {
      type: String,
      default: ''
    },
    overCls: {
      type: String,
      default: ''
    },
    allowClick: {
      type: Boolean,
      default: false
    },
    allowDisabled: {
      type: Boolean,
      default: false
    },
    hasMouseOver: {
      type: Boolean,
      default: false
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    hasQMenu: {
      type: Boolean,
      default: false
    },
    state: {
      type: Number,
      default: 0 // 0=normal 1=active 2=disabled 
    }

  },
  data () {
    return {
      version: '1.0.1',
      overClass: ''
    }
  },
  computed: {
    myClasses () { 
      var mainClass = ''
      mainClass += (this.cls !== '') ? this.cls : ''
      if (this.hasAction === true) {
        mainClass += (this.state === 0 || this.state === 1) ? ' actionIcon' : (this.state === 2) ? ' actionIconNa' : ''
      }
      if (this.state === 1) { // 1=active
        mainClass += ' ' + this.cls + '_active'
      }
      if (this.hasMouseOver && this.overClass !== '') {
        mainClass += (this.overClass !== '') ? ' ' + this.overClass : ''
      }
      if (this.state === 2 && this.allowDisabled) { // 2=disabled
        mainClass += ' ' 
        mainClass += (this.disabledCls !== '') ? this.disabledCls : this.cls + '_disabled'
      }
      return mainClass
    }
  },
  methods: {
    onMouseOver () {
      if (this.hasMouseOver) {
        this.overClass = (this.overCls !== '') ? this.overCls : this.cls + '_over'
        this.$forceUpdate()
      }
    },
    onMouseOut () {
      this.overClass = ''
    },
    onClick (event) {
      if (this.allowClick && this.state !== 2) {
        console.log('mouse click en icono')
        this.$emit('iconClick')
      }
    }
  }
}
</script>

