<template>
  <div>
    <div class="bg-grey-9 row items-center">
      <q-btn icon="north" flat color="white" @click="page --; prevPage = page;" dense :disable="first" />
      <q-separator vertical dark inset />
      <q-btn icon="south" flat color="white" @click="page ++; prevPage = page;" dense :disable="last" />
      <q-form @submit.prevent="page = prevPage">
        <q-input
          dense
          borderless
          dark
          hide-bottom-space
          mask="###"
          :suffix="`de ${pages}`"
          :input-class="!prevPage || prevPage > pages || prevZoom < 1 ? 'text-red text-right' : 'text-right'"
          input-style="width: 32px;"
          v-model="prevPage"
          @input="prevPage = parseInt($event)"
          :rules="[v => !!v && v <= pages && v >= 1]"
          no-error-icon
        />
        <q-btn v-show="false" type="submit" />
      </q-form>
      <q-space />
      <q-btn icon="zoom_out" flat color="white" @click="zoomOut" dense :disable="zoomMin" />
      <q-form @submit.prevent="zoom = prevZoom">
        <q-input
          dense
          borderless
          dark
          hide-bottom-space
          mask="###"
          suffix="%"
          :input-class="!prevZoom || prevZoom > 160 || prevZoom < 40 ? 'text-red text-right' : 'text-right'"
          input-style="width: 32px;"
          v-model="prevZoom"
          @input="prevZoom = parseInt($event)"
          :rules="[v => !!v && v <= 160 && v >= 40]"
          no-error-icon
        />
        <q-btn v-show="false" type="submit" />
      </q-form>
      <q-btn icon="zoom_in" flat color="white" @click="zoomIn" dense :disable="zoomMax" />
      <q-space />
      <q-btn icon="print" flat color="white" @click="print" dense v-if="!$q.screen.lt.sm" />
      <q-btn icon="download" flat color="white" dense @click="download" />
      <a :download="fileName" :href="src" v-show="false" ref="downloader" />
    </div>
    <div class="scroll bg-grey-6 q-pa-lg" style="height: calc(100% - 30px);">
      <pdf
        v-bind="{ key: src, src, page }"
        class="shadow-3"
        :style="{ width: `${zoom}%` }"
        @num-pages="pages = $event || 0"
      />
      <iframe :id="src" :name="src" :src="src" v-show="false"></iframe>
    </div>
  </div>
</template>

<script>


export default {
  name: 'pdf-visor',
  props: {
    src: {
      type: String,
      required: true
    },
    fileName: String
  },
  data () {
    return {
      page: 1,
      zoom: 100,
      prevZoom: 100,
      pages: 0,
      prevPage: 1
    }
  },
  computed: {
    first () {
      return this.page === 1
    },
    last () {
      return this.page === this.pages
    },
    zoomMin () {
      return this.zoom <= 40
    },
    zoomMax () {
      return this.zoom >= 160
    }
  },
  methods: {
    print () {
      var frame = window.frames[this.src]
      frame.focus()
      frame.print()
    },
    download () {
      this.$refs.downloader.click()
    },
    zoomOut () {
      this.zoom = (Math.floor(this.zoom / 10) - 1) * 10
      this.prevZoom = this.zoom.toString()
    },
    zoomIn () {
      this.zoom = (Math.floor(this.zoom / 10) + 1) * 10
      this.prevZoom = this.zoom.toString()
    }
  }
}
</script>
