<template>
  <!-- este compoente provee de un paquete de html, css y js
    comunes en varias partes del sistema para subir documentos
    incluyendo métodos drag and drop
    así debugear y actualizar todos estos puntos en un solo lugar
   -->
  <div
    ref="main"
    class="row items-center justify-center"
    style="width: 240px; height: 230px;border: 2px dashed #DCDBE1;border-radius: 16px;position: relative;"
    :class="dragBg"
  >
    <div class="col text-center" style="max-width: 180px;">
      <input type="file" ref="fileInput" v-bind="$attrs" v-show="false">
      <div class="text-weight-medium q-mb-sm">
        {{ $q.screen.lt.md ? smLabel : mdLabel }}
      </div>
      <q-btn class="q-my-md" color="app_mainBtn" label="Elegir archivo" @click="pickFiles" />
      <div class="text-grey-7 q-mt-sm">
        {{ acceptLabel }}
      </div>
    </div>
    <q-inner-loading :showing="loading">
      <div class="row items-center justify-center">
        <q-circular-progress
          indeterminate
          size="50px"
          color="app_mainBtn"
          class="q-ma-md"
        />
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
export default {
  name: 'input-file',
  props: ['smLabel', 'mdLabel', 'loading', 'acceptLabel', 'value'],
  inheritAttrs: false,
  data () {
    return {
      dragBg: '',
    }
  },
  methods: {
    // en mi opinión este método no debería estar disponible para el componente padre
    // pero aún no me he tomado el tiempo de refactorizar este punto
    initDragDropEvents () {
      // provee eventos drag and drop disponibles soloamente con el alcance
      // dentro de cada declaración de este compomente
      const el = this.$refs.main

      'drag,dragstart,dragend,dragover,dragenter,dragleave'.split(',').forEach((event) => {
        el.addEventListener(event, (e) => {
          e.stopPropagation();
          e.preventDefault();
          e.dataTransfer.dropEffect = this.$q.screen.lt.md ? 'none' : 'copy'
        })
      })

      'dragover,dragenter'.split(',').forEach((event) => {
        el.addEventListener(event, (e) => { 
          e.stopPropagation();
          e.preventDefault();
          this.dragBg = this.$q.screen.lt.md ? '' : 'bg-deep-purple-1'
        })
      })

      'dragend,dragleave'.split(',').forEach((event) => {
        el.addEventListener(event, (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.dragBg = ''
        })
      })

      el.addEventListener('drop', (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.dragBg = ''
        if (e.dataTransfer.files.length) this.getFile(e.dataTransfer.files)
      })

      const elInput = this.$refs.fileInput
      elInput.addEventListener('change', e => {
        this.getFile(elInput.files)
      })
    },
    getFile (files) {
      // obtiene el archivo, de igual manera si es por drag and drop o por input file
      if (!files.length) return
      const file = files[0]
      const validation = this.validateInput(file)
      this.$refs.fileInput.value = ''

      if (validation === true) {
        // envía la información del documento válido
        // con la información que lueog será nuestro parámetro "value"
        // ver v-model de vue
        this.$emit('input', file)
      } else {
        // envía un evento con la información dele rechazo del documento
        // un evento igual al de q file para que sea compatible
        this.$emit('rejected', validation)
      }
    },
    // en mi opinión este método no debería estar disponible para el componente padre
    // pero aún no me he tomado el tiempo de refactorizar este punto
    validateInput (file) {
      // retorna true si el archivo reciibido es válido
      // si no, retorna un arreglo con la información de los errores
      // esta función imita el funcionamiento de q-file
      // el objetivo es ser lo más compatible posible
      const { accept, 'max-file-size': maxFileSize } = this.$attrs
      var errors = []

      //valida la extensión del archivo recibido
      if (accept && !validAccept(accept, file)) {
        errors.push({ failedPropValidation: 'accept', file })
      }

      // valida el tamaño del archivo recibido
      if (maxFileSize && !validFileSize(maxFileSize, file)) {
        errors.push({ failedPropValidation: 'max-file-size', file })
      }

      return !errors.length || errors
    },
    pickFiles () {
      // método diseñado para estar disponible para el componente padre
      // imitando el método disponible de q file para que sea compatible
      this.$refs.fileInput.click()
    }
  },
  mounted () {
    this.initDragDropEvents()
  }
}

// métodos no disponibles para el componente padre
// para que no le cambie el sentido al objetivo de este componente

// retorna false si no es una extensión válida
const validAccept = (accept, file)  => {
  const reg = new RegExp('(.*?)\.(' + accept.replace(/\,/g,'|') + ')$')
  return reg.test(file.name.toLowerCase())
}

// retorna false si el tamaño del archivo no es válido
const validFileSize = (maxFileSize, file) => {
  return file.size <= maxFileSize
}
</script>
