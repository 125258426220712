import { mapGetters } from 'vuex'


//--------------------------
/**
 * Datos generales 
 */
  const dataRef_mesShort=["","Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"]; 
  const dataRef_mes=["","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]; 
  const dataRef_MesesNamesSelect = [
    {name:'Enero', label:'Enero', value: 1 },
    {name:'Febrero', label:'Febrero', value: 2 },
    {name:'Marzo', label:'Marzo', value: 3 },
    {name:'Abril', label:'Abril', value: 4 }, 
    {name:'Mayo', label:'Mayo', value: 5 },
    {name:'Junio', label:'Junio', value: 6 },
    {name:'Julio', label:'Julio', value: 7 },
    {name:'Agosto', label:'Agosto', value: 8 }, 
    {name:'Septiembre', label:'Septiembre', value: 9 },
    {name:'Octubre', label:'Octubre',  value: 10 },
    {name:'Noviembre', label:'Noviembre', value: 11 },
    {name:'Diciembre', label:'Diciembre', value: 12 }
  ]; 
  const dataRef_MesesNames = [
    {name:'Seleccionar', label:'Mes', value:''},
    {name:'Enero', label:'Enero', value: 1 },
    {name:'Febrero', label:'Febrero', value: 2 },
    {name:'Marzo', label:'Marzo', value: 3 },
    {name:'Abril', label:'Abril', value: 4 }, 
    {name:'Mayo', label:'Mayo', value: 5 },
    {name:'Junio', label:'Junio', value: 6 },
    {name:'Julio', label:'Julio', value: 7 },
    {name:'Agosto', label:'Agosto', value: 8 }, 
    {name:'Septiembre', label:'Septiembre', value: 9 },
    {name:'Octubre', label:'Octubre',  value: 10 },
    {name:'Noviembre', label:'Noviembre', value: 11 },
    {name:'Diciembre', label:'Diciembre', value: 12 }
  ];

//--------------------------
/**
 * Datos de Tallas 
 */
const dataRefTallas = [
  {f_val: 127, f_label:'0'  , m_val: 134, m_label: '28', u_val: 758, u_label: 'XS' },
  {f_val: 128, f_label:'3'  , m_val: 135, m_label: '30', u_val: 759, u_label: 'S' },
  {f_val: 129, f_label:'5'  , m_val: 136, m_label: '32', u_val: 760, u_label: 'M' },
  {f_val: 130, f_label:'7'  , m_val: 137, m_label: '34', u_val: 761, u_label: 'L' },
  {f_val: 131, f_label:'9'  , m_val: 138, m_label: '36', u_val: 762, u_label: 'XL' },
  {f_val: 132, f_label:'11' , m_val: 139, m_label: '38', u_val: 763, u_label: 'XXL' },
  {f_val: 133, f_label:'13+', m_val: 140, m_label: '40+', u_val: 763, u_label: 'XXL' }
]

//--------------------------
const helperCatsTallas_Mixin = { 
  created: function () {
    this.$cannaDebug('Helper Catalogs Ref Tallas -- Mixin created')
  },
  computed: {
    list_refTallas () {
      return dataRefTallas
    }
  }
}

const helperCatsFechas_Mixin = {
  created: function () {
    this.$cannaDebug('Helper Catalogs Date Meses -- Mixin created')
  },
  computed: {
    list_dtMesesShort () {
      return dataRef_mesShort
    },
    list_dtMeses () {
      return dataRef_mes
    },
    list_dtRefMeses () {
      return dataRef_MesesNames 
    },
    list_refMesesSelect () {
      return dataRef_MesesNamesSelect
    }
  }
}

const helperCats_Mixin = {
  created: function () {
    this.$cannaDebug('Helper Catalogs -- Mixin created')
  },
  data () {
    return {
      zz_helperCatalogsMixinVer: '1.0.1'
    }
  },
  computed: {
  },
  methods: {
    async helper_getAllCatalogs () { // Solo para llamadas get 
      console.log('--- helper_getAllCatalogs')
      /**
       * Obtiene solo los catalogos (sin datos para control de los catalogos)
       * 
       * ex:  helpermix_getCatalog (0)
       */
      // ${secure}://${server}/index.php/api/v2/categoria?per-page=all&expand=catalogos
      var dataUse = null
      var mxParams = null
      var myDataObj = null
      // ------------
      var mxModel = this.getModelRequest('helperCategorias')
      mxParams = { 'per-page': 'all', sort:'id' } //  expand: 'catalogos'
      var myConfig = this.createAxiosConfig('get', mxModel, mxParams, true, null)
      // ---------
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      await this.doRequestByPromise(this.$apiV2, 'helper', reqRes.action, myConfig).then((resx) => {
        console.log ('Action -- Carga de helper All catalogos -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      // ---------
      var mydataC = reqRes.action[0].data.data // Todas las categorias y todos los catalogos 
      var hCat = this.$store.state.myapp.helperCatalog
      // mydataC.forEach((categ, index) => {
      var  fieldNotUsed = ['created_by', 'created_at','updated_by', 'updated_at','deleted_by','deleted_at','_links']
      mydataC.forEach ( itemCat => {
        fieldNotUsed.forEach( itemDel => {
          delete itemCat[itemDel]
        })
        itemCat.dataLoaded = false
        itemCat.dataCatalog = []
        //itemCat.dataCatalogMovil = []
      })
      // ----------------------
      this.$store.commit('myapp/setMainState', { 
        helperCatalog: mydataC, 
        helperCatalogMobil: JSON.parse(JSON.stringify(mydataC)) // mydataC.slice(0) 
      })
      await this._waitRequestProc(200)
      return true
    },
    async helpermix_getCatalog (catalogoIdx, params, sortOnId ) { // Solo para llamadas get 
      /**
       * 
       * catalogoIdx = el id del catalogo a obtener
       * params = los parametros a usar en caso de hacer request 
       * 
       * ex:  helpermix_getCatalog (6, { delimiters:[6], 'per-page':'all', sort=nombre&expand=categoria })
       */
      this.$cannaDebug('Helper -- helpermix getCatalog -- ' + catalogoIdx)
      var catalogoIDxUse = catalogoIdx
      var test = 11
      var  fieldNotUsed = ['created_by', 'created_at','updated_by', 'updated_at','deleted_by','deleted_at','_links']
      var mxParams = null
      var myDataObj = this.getHelperCat(catalogoIDxUse)

      if (myDataObj.dataLoaded === false && myDataObj.dataCatalog.length<=0){ // Cargamos el catalogo 

        // ---------
        var mxModel = this.getModelRequest('helperCatCatalog')
        if (params === undefined || params === null) { 
          mxParams = { delimiters: [catalogoIDxUse], 'per-page': 'all', sort: 'id', expand:'catalogos' }
        } else { 
          mxParams = JSON.parse(JSON.stringify(params))
        }
        var myConfig = this.createAxiosConfig('get', mxModel, mxParams, true, null)
        // ---------
        var reqRes = { action: [] }
        var reqErr = { action: 0 }
        await this.doRequestByPromise(this.$apiV2, 'helper', reqRes.action, myConfig).then((resx) => {
          console.log ('Action -- Carga de helper catalogo -- GET ejecutado')
          var tmpresponseData = reqRes.action[reqRes.action.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.action += 1
          }
        })
        // ---------
        test = 21
        if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          return null
        }
        test = 22
        var myDataRet = reqRes.action[0].data.data // deben de ser arrays de datos 
        
        myDataRet.forEach(itemC => {
          // if (catalogoIDxUse === 6 || catalogoIDxUse === 7) {
          //   itemC.categoria_customLabel = 'Puestos ' + itemC.nombre
          // } else { 
          //   itemC.categoria_customLabel = itemC.nombre
          // }
          fieldNotUsed.forEach( itemDel => {
            delete itemC[itemDel]
          })
        })
        var DataUse = []   // JSON.parse(JSON.stringify(myDataRet))
        var zDataUseAlt= [] // JSON.parse(JSON.stringify(myDataRet))
        var campoZZ = null 
        // Ajustamos el orden de los datos

        myDataRet.forEach( itemWW => {
          var itemNuevo = JSON.parse(JSON.stringify(itemWW))
          itemNuevo.tipoDato = 'web'

          var itemNuevoMovil = JSON.parse(JSON.stringify(itemWW))
          itemNuevoMovil.tipoDato = 'movil'

          DataUse.push (itemNuevo)
          zDataUseAlt.push (itemNuevoMovil)
        })
        

        if (catalogoIdx === 27) { // Nacionalidad (lista completa) // Orden por nombre siempre 

          campoZZ = 'descripcion'
          DataUse.sort(function (a, b) {
            const Ao = a.descripcion
            const Bo = b.descripcion
            return Ao.localeCompare(Bo, 'es', { sensitivity: 'base' })
          })
          // zDataUseAlt =  DataUse.slice(0) // categ._embedded.catalogos JSON.parse(JSON.stringify(DataUse))
          zDataUseAlt.sort(function (az, bz) {
            const Ax = az.descripcion
            const Bx = bz.descripcion
            let comparisonx = 0
            comparisonx = (Ax > Bx) ? 1 : (Ax < Bx) ? -1 : 0
            return comparisonx
          })

        } else { // Lo demas se ordena todo por orden_web y orden_movil

          
          // para efecto de Orden web o casos especiales 
          if (sortOnId === undefined || sortOnId === '' || sortOnId === false || sortOnId === null ) { 

            // Cambio : se ordena todo por orden_web (default)
            campoZZ = 'orden_web'
            DataUse.sort(function (a, b) {
              const Ao = a.orden_web
              const Bo = b.orden_web
              let comparison = 0
              comparison = (Ao > Bo) ? 1 : (Ao < Bo) ? -1 : 0
              return comparison
            })


          } else {

            // Cambio : se ordena todo por ID
            campoZZ = 'id'
            DataUse.sort(function (a, b) {
              const Ao = a.id
              const Bo = b.id
              let comparison = 0
              comparison = (Ao > Bo) ? 1 : (Ao < Bo) ? -1 : 0
              return comparison
            })

          }
          // Cambio : Copia ordenada x orden_movil (default)
          zDataUseAlt.sort(function (az, bz) {
            const Ax = az.orden_movil
            const Bx = bz.orden_movil
            let comparisonx = 0
            comparisonx = (Ax > Bx) ? 1 : (Ax < Bx) ? -1 : 0
            return comparisonx
          })

        }

        // Se actualiza en el store 
        this.$store.commit('myapp/setHelperCatInfo', { catId: catalogoIdx, data: { dataLoaded: true } })
        this.$store.commit('myapp/setHelperCatData', { 
          catId: catalogoIdx, 
          data: JSON.parse(JSON.stringify(DataUse)) //,datamovil: JSON.parse(JSON.stringify(zDataUseAlt))
        })
        await this._waitRequestProc(200)

        this.helpermix_createMovil(catalogoIdx)
        // this.$store.commit('myapp/setHelperCatDataSORT', { catId: catalogoIdx, campoUse: campoZZ })
        //this.$store.commit('myapp/setHelperCatMobilInfo', { catId: catalogoIdx, usedata: { dataLoaded: true } })
        //this.$store.commit('myapp/setHelperCatMobilData', { catId: catalogoIdx, usedata: JSON.parse(JSON.stringify(zDataUseAlt)) })

        await this._waitRequestProc(200)

        this.$forceUpdate()
        return true

      } else { // Ya esta Cargado el catalogo
        return true 
      }
    },

    async helpermix_createMovil (catalogoIdx) { // temporal de pruebas 

      this.$cannaDebug('Helper -- helpermix getCatalog -- ' + catalogoIdx)
      var myCatalogoData = JSON.parse(JSON.stringify(this.getHelperCat(catalogoIdx).dataCatalog))
      myCatalogoData.forEach( itemWW => {
        itemWW.tipoDato = 'movil'
      })
      myCatalogoData.sort(function (az, bz) {
        const Ax = az.orden_movil
        const Bx = bz.orden_movil
        let comparisonx = 0
        comparisonx = (Ax > Bx) ? 1 : (Ax < Bx) ? -1 : 0
        return comparisonx
      })
      this.$store.commit('myapp/setHelperCatMobilInfo', { catId: catalogoIdx, usedata: { dataLoaded: true } })
      this.$store.commit('myapp/setHelperCatMobilData', { catId: catalogoIdx, usedata: JSON.parse(JSON.stringify(myCatalogoData)) })
      await this._waitRequestProc(300)
      this.$forceUpdate()

    }

  }
}

export default helperCats_Mixin

export {
  helperCatsFechas_Mixin,
  helperCatsTallas_Mixin
}