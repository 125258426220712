<template>
    <div class="canna_pageHeader">

      <template v-if="showTopTlbZone">
        <div class="tlbTop_zone"><!-- mas contenido en la parte superior-->
          <slot name="toptlbZone">
            TOP - Otro contenido
          </slot>
        </div>
      </template>

      <div class="main_zone">
        <div class="noselect title_zone">
          <div class="page_title">
            <slot>
              TITULO de pagina
            </slot>
          </div>
          <template v-if="showSubtitle">
            <div class="page_subTitle" :style="(style_Subtitle!=='') ? style_Subtitle : ''">
              <slot name="subtitle">
                Subtitulo
              </slot>
            </div>
          </template>
        </div>
        <template v-if="showTitleAltZone">
          <div class="title_altZone" :class="(cls_title_altZone!=='') ? cls_title_altZone : ''">
            <slot name="altZone">
              BOTONES
            </slot>
          </div>
        </template>
      </div>

      <template v-if="showTlbZone">
        <div class="tlb_zone"><!-- mas contenido en la parte inferior-->
          <slot name="tlbZone">
            Otro contenido
          </slot>
        </div>
      </template>


    </div>
</template>

<script>
export default {
  name: 'canna_pageHeader',
  props: {
    showSubtitle: {
      type: Boolean,
      default: false
    },
    showTitleAltZone: {
      type: Boolean,
      default: false
    },
    showTlbZone: {
      type: Boolean,
      default: false
    },
    showTopTlbZone: {
      type: Boolean,
      default: false
    },
    cls_title_altZone: { 
      type: String,
      default: ''
    },
    style_Subtitle: {
      type: String,
      default: ''
    }
  },
  data () { 
    return {
      version: '101'
    }
  }
}
</script>
