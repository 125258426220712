

const questionario_Mixin = {
  created: function () {
    this.$cannaDebug('Questionario -- Mixin created')
  },
  data () {
    return {
      zz_questionarioMixinVer: '1.0.1'
    }
  },
  computed: {
    qa_currProfile_status (){
      return parseInt(this.getProfileData.status)
    },
    qa_allowReviewLink () {
      var tmpEstatus = parseInt(this.getProfileData.status)
      return (tmpEstatus >= this.zstatUser.REDES_SOCIALES ) ? true : false  // 25 - ahora 26 
    }
  },
  methods: {
    goTo_PreviewPage (){
      var newRuta = '/dashboard/personal/revision'
      this.$router.push(newRuta)
    }
  }
}

export default questionario_Mixin
