

/* Generales */
const jps_isObjectFn = function (o) {
  return o !== null && typeof o === 'object' && Array.isArray(o) === false
}

const sectionRutas = [
  {id: 1, routeName: 'dashboard_pi_foto', zona:'a' },
  {id: 2, routeName: 'dashboard_pi_puestos', zona:'a' },
  {id: 3, routeName: 'dashboard_pi_sueldos', zona:'a' },
  {id: 4, routeName: 'dashboard_pi_horario', zona:'a' },

  {id: 5, routeName: 'dashboard_pi_direccion', zona:'b' },
  {id: 6, routeName: 'dashboard_pi_pais', zona:'b' },
  {id: 7, routeName: 'dashboard_pi_licencia', zona:'b' },
  {id: 8, routeName: 'dashboard_pi_tieneauto', zona:'b' },

  {id: 9, routeName: 'dashboard_pi_medidas', zona:'c' },

  {id: 10, routeName: 'dashboard_pi_escuela', zona:'d' },
  {id: 11, routeName: 'dashboard_pi_canales', zona:'d' },
  {id: 12, routeName: 'dashboard_pi_experiencialaboral', zona:'d' },
  {id: 13, routeName: 'dashboard_pi_curriculum', zona:'d' },

  {id: 14, routeName: 'dashboard_pi_sociales', zona:'e' },
  {id: 15, routeName: 'dashboard_pi_review', zona:'e' }
]
// ---------------
// ----------------
const codeByScreen = {

}

const codeReview  = {

}


export { 
  sectionRutas,
  codeByScreen,
  codeReview
}